.calendar {
  background-color: white;
  padding: 25px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 10px;
  position: fixed;
  z-index: 10;
}

.list {
  position: relative;
  width: 100%;
  top: 480px;
  z-index: 0;
  overflow: hidden !important;
  padding-bottom: 45px;
}

.eventList {
  padding: 0;
  background-color: white;
}

.clickedDate {
  list-style-type: none;
  border-bottom: 2px solid #ebebeb;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  padding: 5px 33px;
  background-color: azure;
}

.clickedDateItem {
  margin: auto 0;
  font-size: 1.2em;
  font-weight: bold;
  height: 100%;
}

.eventContents {
  list-style-type: none;
  border-bottom: 2px solid #ebebeb;
  padding: 15px 0;
}
.eventContents:last-child {
  border: none;
}
.eventItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  color: black;
}

.eventAmount {
  margin-right: 30px;
  position: relative;
  top: 5px;
}

.detail {
  margin-left: 20px;
}

.icon {
  position: relative;
  top: 7px;
  margin-right: 10px;
}
