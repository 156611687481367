/* フォームのコンテナ */
.container {
  background-color: white;
  padding: 25px 0 60px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.form {
  margin: 30px 0;
  text-align: center;
}

.addButton {
  margin: 30px 20px;
  text-align: left;
}

.desc {
  margin-top: 10px;
  margin-left: 10px;
  width: 90%;
}

.discContents {
  text-align: left;
}

.delete {
  position: absolute;
  top: 10px;
  right: 20px;
}

.additionalForm {
  border: 1px dashed #3788d8;
  margin: 0 15px;
}

.modal {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 150%;
  text-align: center;
  line-height: 35em;
  background-color: rgba(185, 185, 185, 0.5);
}

.modalContainer {
  background-color: white;
  margin: 50px 40px 0 40px;
  position: fixed;
  z-index: 10;
  height: 400px;
  top: 15%;
  width: 80%;
}

.modalList {
  position: relative;
  top: 20%;
}

.listDisplay {
  list-style-type: none;
  border-bottom: 2px solid #ebebeb;
  padding: 15px 0;
}

.listItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 20px;
  margin-bottom: 20px;
}

.itemName {
  height: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
}

.icon {
  margin-right: 10px;
  margin-top: 7px;
}
