/* 全体のスタイリング */
body {
  font-family: "Arial", sans-serif;
  background-color: #ebebeb !important;
  align-items: center;
  margin: 0;
  width: 100%;
  height: auto;
  overflow-x: hidden;
}

/* タイトル */
h2 {
  margin: 0 0 20px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

/* .MuiBottomNavigation-root {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 36px;
  z-index: 1000;
} */
