/* フォームのコンテナ */
.container {
  background-color: white;
  padding: 25px 0 5px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  z-index: 10;
}

.selector {
  margin: 0 25px;
  font-size: 28px;
  font-weight: bold;
}

.list {
  position: relative;
  width: 100%;
  top: 415px;
  z-index: 0;
  overflow: hidden !important;
  padding-bottom: 45px;
}

.eventList {
  padding: 0;
  background-color: white;
}

.listDisplay {
  list-style-type: none;
  border-bottom: 2px solid #ebebeb;
  padding: 15px 0;
}
.listDisplays:last-child {
  border: none;
}

.listHidden {
  display: none;
}

.listItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.itemName {
  margin-left: 20px;
}
.itemValue {
  margin-right: 30px;
  margin-top: 5px;
}

.noData {
  text-align: center;
  margin-top: 96px;
}

.total {
  display: flex;
  background-color: white;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1px;
  border-top: 0.5px solid;
}

.totalTitle {
  margin-left: 20px;
}
.totalContents {
  margin-right: 30px;
}

.icon {
  position: relative;
  top: 5px;
  margin-right: 10px;
}
