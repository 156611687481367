.container {
  background-color: white;
  padding: 25px 0 5px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 600px;
}

.contents {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.form {
  margin: 30px 0;
  text-align: center;
}

.toBack {
  margin: 30px auto;
  width: 90%;
  display: block;
}

.modal {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 35em;
  background-color: rgba(185, 185, 185, 0.5);
}

.modalContainer {
  background-color: white;
  margin: 50px 40px 0 40px;
  position: relative;
  z-index: 10;
  height: 450px;
  top: 15%;
}

.icon {
  margin-right: 10px;
  margin-top: 7px;
}

.list {
  padding-left: 18%;
}

.listItem {
  list-style-type: none;
}

.formContainer {
  height: 100%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
}

.form {
  text-align: center;
  height: 30px;
}

.contactForm {
  margin: 30px 0;
  text-align: center;
}
