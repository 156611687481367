/* フォームのコンテナ */
.container {
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
}

.form {
  margin: 30px 0;
  text-align: center;
}

.subText {
  text-align: center;
}

/* 送信ボタン */
.submit {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
  background-color: #3490dc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px !important;
}

.submit:hover {
  background-color: #2779bd;
}

.google {
  width: 70%;
  justify-content: center;
  height: 45px;
  font-size: 16px !important;
  font-weight: bold !important;
  color: black !important;
  /* border: 1px black solid; */
}

.google > div {
  padding: 12px 2px 8px 10px !important;
}

.authForm {
  margin: 20px 0 10px 0;
  text-align: center;
}

.description {
  margin: 0 auto;
  width: 90%;
  display: block;
}

.modal {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 88%;
  height: 100%;
  text-align: center;
  line-height: 35em;
  background-color: rgba(185, 185, 185, 0.5);
}
